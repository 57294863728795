import axios from "axios";
import { useCallback, useEffect, useState } from "react";

import { useMsal } from "@azure/msal-react";
import { Dialog, Loader } from "@fluentui/react-northstar";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { loginRequest } from "./msal-auth/msalAuth";

import config from "config";
import OnboardingProvider from "context/OnboardingContext";
import { useTranslation } from "react-i18next";

import ScrollToTop from "helpers/ScrollToTop";
import Auth from "pages/Auth";

import "styles/blocks.css";
import "styles/custom-bootstrap.css";
import "styles/global.css";
import "./App.css";

import { UserContext } from "context/UserContext";
import Assignment from "pages/Assignment";
import Attendance from "pages/Attendance";
import Authorities from "pages/Authorities";
import Dashboard from "pages/Dashboard";
import Events from "pages/Events";
import Feedback from "pages/Feedback";
import Home from "pages/Home";
import PageNotFound from "pages/PageNotFound";
import Parent from "pages/Parent";
import { CustomerContext } from "pages/Payment/Customer/context/customer.context";
import Price from "pages/Payment/Price";
import Subscribe from "pages/Payment/Subscribe";
import SuccessPayment from "pages/Payment/Success-payment";
import Privacy from "pages/Privacy";
import Profile from "pages/Profile";
import Refer from "pages/Refer";
import School from "pages/School";
import Support from "pages/Support";
import TermsOfUse from "pages/Terms";
import Whiteboard from "pages/Whiteboard";
import Whiteboard2 from "pages/Whiteboard2";
import { useGoogleLogout } from "react-google-login";
import PurchaseCredit from "pages/Credit/PurchaseCredit";
import Magicbar from "pages/Magicbar";
import NonSubScriber from "pages/NonSubScripber";
import UnsubscribeNotification from "pages/UnsubscribeNotification";
import Bookings from "pages/Bookings";
import Diagram from "pages/Diagram";
import SpinWheel from "pages/SpinWheel";
import VideoAvatars from "pages/Gan";
import Storage from "pages/Storage";
import { useVerifyCouponHandlers } from "hooks";
import GoogleDisclosure from "pages/Privacy/GoogleDisclosure";

const App = () => {
  const { t } = useTranslation();

  const { instance, accounts, inProgress } = useMsal();
  const [coupon, setCoupon] = useState("");
  const [loader, setLoader] = useState(coupon ? true : false);
  const { verifyCoupon, coupon: urlCoupon } = useVerifyCouponHandlers({
    onAfterVerified: () => setLoader(false),
  });

  const [user, setUser] = useState(null);
  const [noDbFound, setNoDbFound] = useState(false);
  const [pop, setPop] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [nonSubscriber, setNonSubscriber] = useState(false);
  const [customer, setCustomer] = useState({
    email: "",
    name: "",
    school: "",
    phone: "",
    address: "",
    job: "",
  });
  const [subscriptionId, setSubscriptionId] = useState(null);

  const [graphData, setGraphData] = useState(null);
  const [isTrial, setIsTrial] = useState(false);

  useEffect(() => {
    setCoupon(urlCoupon);
  }, [urlCoupon]);

  const { signOut } = useGoogleLogout({
    onFailure: (err) => {
      console.log(err);
    },
    clientId: config.GOOGLE_CLIENT_ID,
    onLogoutSuccess: (res) => {
      localStorage.removeItem("isSignIn");
      localStorage.removeItem("googleToken");
      localStorage.removeItem("selectedTeacherCount");
      window.location.href = "/auth";
      setUser(null);
    },
  });
  const handleLogin = (type, res) => {
    if (type === "google") {
      localStorage.removeItem("IsUserCreateAccount");
      localStorage.setItem("googleToken", JSON.stringify(res));
      localStorage.setItem("isSignIn", "true");
      setGoogleData(res);
      return;
    } else {
      localStorage.removeItem("googleToken");
      if (window.parent !== window) {
        instance.loginPopup(loginRequest).catch((e) => {
          console.error(e);
          const from = localStorage.getItem("from");
          from && localStorage.removeItem("from");
        });
      } else {
        instance.loginRedirect(loginRequest).catch((e) => {
          console.error("REDIRECT ERROR", e);
          const from = localStorage.getItem("from");
          from && localStorage.removeItem("from");
        });
      }
      localStorage.setItem("isSignIn", "true");
    }
  };

  const token = localStorage.getItem("googleToken");
  const handleLogout = () => {
    if (token) {
      signOut();
    } else {
      if (window.parent !== window) {
        localStorage.clear();
        location.reload();
      } else {
        instance.logoutRedirect().catch((e) => {
          console.error(e);
        });
        localStorage.removeItem("isSignIn");
        localStorage.removeItem("selectedTeacherCount");
      }
    }
    setUser(null);
  };

  useEffect(() => {
    if (token) {
      setGoogleData(JSON.parse(token));
    }
  }, [token]);

  const setGoogleData = (res) => {
    if (res && res.profileObj) setLoader(true);
    setGraphData({
      givenName: res.profileObj.givenName,
      displayName: res.profileObj.givenName + res.profileObj.familyName,
      // id: res.profileObj.sub,
      mail: res.profileObj.email,
      surname: res.profileObj.familyName,
      userPrincipalName: res.profileObj.email,
    });
    setAccessToken(res?.tokenObj?.id_token);
  };

  const getUserdata = () => {
    if (accounts && accounts.length) {
      const accessTokenRequest = {
        ...loginRequest,
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((response) => {
          setAccessToken(response.accessToken);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then((response) => {
                setAccessToken(response.accessToken);
              })
              .catch((error) => {
                console.error("GET USER DATA", error);
              });
          }
        });
    }
  };

  const getDBdata = async (dbData) => {
    const database = dbData?.db || null;
    if (database) {
      let schoolInfo = dbData?.schoolInfo || null;
      const slug = "?slug=" + database;

      let userinfo = {};
      await axios
        .get(
          `${config.ENDPOINT}/api/user/generate-shared-access-signature${slug}`,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((res) => {
          userinfo = {
            ...userinfo,
            SASToken: "?" + res.data.SASToken,
            slug: "?slug=" + database,
            schoolInfo: schoolInfo,
          };
        })
        .catch((err) => {
          console.error(err);
        });

      await axios
        .get(`${config.ENDPOINT}/api/user/check-user-role${slug}`, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((res) => {
          const { image } = res.data;
          if (res.data.role !== "SuperAdmin") {
            setNonSubscriber(true);
            setLoader(false);
            return;
          }
          const role = {
            role: res.data.role,
            actualRole: res.data.actualRole,
          };
          userinfo = {
            ...userinfo,
            ...role,
            ...res.data.user,
            uProfile: image ? image + userinfo?.SASToken : null,
          };
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            setNonSubscriber(true);
            setLoader(false);
            return;
          }
          setNoDbFound(true);
          setPop({
            head: t("errors")[0],
            msg: t("errors")[1],
          });
        });
      setLoader(false);

      await axios
        .post(
          `${config.ENDPOINT}/api/startup/get-DB-data${slug}`,
          {
            tenantId: "12d105e3-e51a-4c7f-843b-50046ad42224",
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        )
        .then((res) => {
          userinfo = {
            ...userinfo,
            dbData: {
              ...res.data.result,
              ...res.data.expDate,
              dayRemaining: res.data.dayRemaining,
            },
          };
        })
        .catch((err) => {
          console.error(err);
        });
      if (accounts[0]) {
        setUser({
          ...user,
          ...userinfo,
          ...accounts[0],
          UrlPhoto: userinfo?.uProfile,
        });
      } else {
        setUser({
          ...user,
          ...userinfo,
          name: graphData?.givenName,
          UrlPhoto: userinfo?.uProfile,
        });
      }
    } else {
      setNoDbFound(true);
      setPop({
        head: t("errors")[2],
        msg: t("errors")[3],
      });
    }
  };

  useEffect(() => {
    const isRequestForDemo = localStorage.getItem("RequestDemo");
    if (isRequestForDemo) {
      localStorage.setItem("RequestDemo", true);
    }
  }, [localStorage.getItem("RequestDemo")]);

  useEffect(() => {
    if (accessToken) {
      if (accounts.length) {
        setUser({ ...accounts[0] });
        getDb({ ...accounts[0] });
      } else {
        setUser({
          username: JSON.parse(token)?.profileObj?.email,
        });
        getDb({
          username: JSON.parse(token)?.profileObj?.email,
        });
      }
    }
  }, [accessToken]);

  useEffect(() => {
    if (nonSubscriber) {
      setLoader(false);
    }
  }, [nonSubscriber]);

  useEffect(() => {
    if (!loader) {
      verifyCoupon();
    }
  }, [loader]);

  const getDb = (userData) => {
    setLoader(true);
    axios
      .get(
        `${config.ENDPOINT}/api/user/get-user-datbase/${userData.username}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then(async (res) => {
        const multiDB = res.data.result;
        if (multiDB.length === 0) {
          setNonSubscriber(true);
          setLoader(false);
          return;
        }

        const selectedDB = multiDB.find((x) => x.selectedDB === 1);
        if (selectedDB) {
          const data = {
            db: selectedDB.database,
            schoolInfo: selectedDB.schoolInfo,
          };
          getDBdata(data);
        } else {
          const database = multiDB[0] || null;
          if (database) {
            const data = {
              db: database.database,
              schoolInfo: database.schoolInfo,
            };
            getDBdata(data);
          }
        }
      })
      .catch((err) => {
        console.error("get db", err);
        setNoDbFound(true);
        setPop({
          head: t("errors")[4],
          msg: t("errors")[5],
        });
      });
  };

  useEffect(() => {
    if (user && user.slug && !nonSubscriber) {
      axios
        .get(`${config.ENDPOINT}/api/school/get-school-info${user?.slug}`, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        })
        .then((res) => {
          setIsTrial(res.data?.result.isTrial || null);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user, accessToken]);

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      getUserdata();
    }
  }, [instance, accounts, inProgress]);

  const IsUserCreateAccount = Boolean(
    localStorage.getItem("IsUserCreateAccount")
  );
  useEffect(() => {
    if (IsUserCreateAccount && user) {
      // Use a functional update to ensure the state update doesn't lead to an infinite loop
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        name: user?.name,
        email: user?.username,
      }));
      setSubscriptionId(localStorage.getItem("subscriptionId"));
    }
  }, [IsUserCreateAccount, user]);

  return (
    <div className="Landing">
      <OnboardingProvider>
        <CustomerContext.Provider
          value={{ customer, setCustomer, subscriptionId, setSubscriptionId }}
        >
          <UserContext.Provider
            value={{
              user,
              accessToken,
              nonSubscriber,
              setUser,
              setAccessToken,
              setNonSubscriber,
              isTrial,
            }}
          >
            <Router>
              <ScrollToTop />
              <Dialog
                open={noDbFound}
                onConfirm={() => handleLogout()}
                confirmButton={t("buttons")[0]}
                content={pop?.msg}
                header={pop?.head}
              />
              <Switch>
                {loader ? (
                  <Loader size="medium" style={{ paddingTop: "50vh" }} />
                ) : (
                  <>
                    {accessToken ? (
                      <>
                        <Route exact path="/profile">
                          {/* Non-subscriber: If a user login with Microsoft and Google credentials but is not registered in the ACV. */}
                          {nonSubscriber ? (
                            <Redirect to="/non-subscriber" />
                          ) : (
                            <Profile
                              login={handleLogin}
                              logout={() => handleLogout()}
                            />
                          )}
                        </Route>
                        <Route exact path="/prices">
                          <Price
                            login={handleLogin}
                            logout={handleLogout}
                            setLoader={setLoader}
                          />
                        </Route>
                        <Route exact path="/credits">
                          <PurchaseCredit />
                        </Route>
                        <Route exact path="/storage">
                          <Storage />
                        </Route>
                        <Route exact path="/prices/:token">
                          <Price
                            login={handleLogin}
                            logout={handleLogout}
                            setLoader={setLoader}
                          />
                        </Route>
                        <Route exact path="/auth">
                          <Auth
                            login={handleLogin}
                            logout={() => handleLogout()}
                          />
                        </Route>
                        <Route exact path="/non-subscriber">
                          <NonSubScriber logout={() => handleLogout()} />
                        </Route>
                      </>
                    ) : (
                      <>
                        <Route exact path="/prices/:token">
                          <Price login={handleLogin} logout={handleLogout} />
                        </Route>
                        <Route exact path="/prices">
                          <Price login={handleLogin} setLoader={setLoader} />
                        </Route>
                        <Route exact path="/credits">
                          <PurchaseCredit />
                        </Route>
                        <Route exact path="/storage">
                          <Storage />
                        </Route>
                        <Route exact path="/profile">
                          <Redirect
                            to={{
                              pathname: "/auth",
                              state: { from: "/profile" },
                            }}
                          />
                        </Route>
                        <Route exact path="/auth">
                          <Auth
                            login={handleLogin}
                            logout={() => handleLogout()}
                          />
                        </Route>
                        <Route exact path="/non-subscriber">
                          <Redirect to="/" />
                        </Route>
                        {/* <Route exact path="/credit">
                          <Redirect
                            to={{
                              pathname: "/auth",
                              state: { from: "/credit" },
                            }}
                          />
                        </Route> */}
                      </>
                    )}

                    <Route exact path="/">
                      <Home />
                    </Route>
                    <Route exact path="/support">
                      <Support />
                    </Route>
                    <Route exact path="/refer">
                      <Refer />
                    </Route>
                    <Route exact path="/privacy">
                      <Privacy />
                    </Route>
                    <Route exact path="/google-disclosure">
                      <GoogleDisclosure />
                    </Route>
                    <Route exact path="/termsofuse">
                      <TermsOfUse />
                    </Route>
                    <Route exact path="/terms">
                      <TermsOfUse />
                    </Route>
                    <Route exact path="/register">
                      <Price />
                    </Route>
                    <Route exact path="/contact">
                      <Home />
                    </Route>
                    <Route exact path="/demo">
                      <Home />
                    </Route>
                    <Route exact path="/subscribe">
                      <Subscribe />
                    </Route>
                    <Route exact path="/school">
                      <School />
                    </Route>
                    <Route exact path="/parent">
                      <Parent />
                    </Route>
                    <Route exact path="/attendance">
                      <Attendance />
                    </Route>
                    <Route exact path="/dashboard">
                      <Dashboard />
                    </Route>
                    <Route exact path="/whiteboard">
                      <Whiteboard />
                    </Route>
                    <Route exact path="/assignment">
                      <Assignment />
                    </Route>
                    <Route exact path="/events">
                      <Events />
                    </Route>
                    <Route exact path="/bookings">
                      <Bookings />
                    </Route>
                    <Route exact path="/diagram">
                      <Diagram />
                    </Route>
                    <Route exact path="/spin-wheel">
                      <SpinWheel />
                    </Route>
                    <Route exact path="/videoAvatars">
                      <VideoAvatars />
                    </Route>
                    <Route exact path="/whiteboard2">
                      <Whiteboard2 />
                    </Route>
                    <Route exact path="/leadership">
                      <Authorities />
                    </Route>
                    <Route exact path="/educator">
                      <Parent />
                    </Route>
                    <Route exact path="/students">
                      <School />
                    </Route>
                    <Route exact path="/feedback">
                      <Feedback />
                    </Route>
                    <Route exact path="/ai">
                      <Magicbar />
                    </Route>
                    <Route exact path="/authority-info">
                      <Authorities />
                    </Route>

                    <Route exact path="/success-payment">
                      <SuccessPayment />
                    </Route>

                    <Route path="/unsubscribe">
                      <UnsubscribeNotification />
                    </Route>

                    <Route exact path="/page-not-found">
                      <PageNotFound />
                    </Route>
                  </>
                )}
              </Switch>
            </Router>
          </UserContext.Provider>
        </CustomerContext.Provider>
      </OnboardingProvider>
    </div>
  );
};

export default App;
