import React from "react";
import { useTranslation } from "react-i18next";
import CommanHeader from "components/Header";
import Footer from "components/Footer";

import parse from "html-react-parser";

const GoogleDisclosure = (props, { insideTeam }) => {
  const { t } = useTranslation();
  return (
    <div className="Landing Privacy font-1">
      <CommanHeader />
      <div class="container py-8">
        <h1>{t("disclosure").disclosure_heading}</h1>
        <p>{parse(t("disclosure").desc)}</p>
      </div>
      <Footer />
    </div>
  );
};

export default GoogleDisclosure;
